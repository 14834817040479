<template>
 <SalesGrowthWrap>
  <sdCards title="Sales per maand">
   <a-row>
    <a-col :xs="24">
     <div class="growth-chart-wrap">
      <Chartjs
       :key="monthlySaleCounts"
       :datasets="[
                {
                  data: monthlySaleCounts,
                  backgroundColor: '#5F63F250',
                  hoverBackgroundColor: '#5F63F2',
                  maxBarThickness: 10,
                  barThickness: 12,
                  label: 'Orders',
                  barPercentage: 1,
                },
              ]"
       :height="300"
       :labels="['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']"
       :options="chartOptions"
       class="growth"
       type="bar"
      />
     </div>
    </a-col>
   </a-row>
  </sdCards>
 </SalesGrowthWrap>
</template>
<script>
import {SalesGrowthWrap} from '../../style';
import Chartjs from '../../../../components/utilities/chartjs';
import {defineComponent} from 'vue';
import VueTypes from "vue-types";

const chartOptions = {
 responsive: true,
 maintainAspectRatio: true,
 legend: {
  display: false,
  labels: {
   display: false,
  },
 },
 scales: {
  yAxes: [
   {
    stacked: true,
    gridLines: {
     display: false,
    },
    ticks: {
     display: false,
    },
   },
  ],
  xAxes: [
   {
    stacked: true,

    gridLines: {
     display: false,
    },
    ticks: {
     display: true,
    },
   },
  ],
 },
};

const SalesGrowth = defineComponent({
 name: 'SalesGrowth',
 components: {SalesGrowthWrap, Chartjs},
 data() {
  return {
   chartOptions,
  };
 },
 props: {
  monthlySaleCounts: VueTypes.array.def([]),
 },
});

export default SalesGrowth;
</script>
